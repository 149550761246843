import React from "react";

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import Main from '../components/ui/main';
import H1 from '../components/ui/h1';
import Section from '../components/ui/section';
import Mailto from 'react-protected-mailto'

class WhoPage extends React.Component {
  render() {
    return (
      <Layout>
        <Header />
        <SEO
          description="The Jeffrey Epstein Ranking provides a classification of Universities according to the donations received via the scientific broker Jeffrey Epstein."
          title="How is JER™ assessed"
        />

        <Main>
          <Section>
            <H1 class="py-6 mb-6">How is the JER™ assessed: In dubio pro reo </H1>
            <div className="max-w-full mx-auto w-large">
              <p>Those accustomed to the populist public universities where any student group, trade union or digital media feels entitled to demand explanations to university
                presidents, might disregard as insignificant the data mining required for the elaboration of this index. What they ignore though is that we are talking about
                élite universities (most of them private, including more than half of the Ivy League members) with the highest confidentiality standards for donations.
              </p>
              <p>
                Hence the reservations with which the spokepeople of both leaders of JER™  answered to the first inquiries: on one side, Jason Newton stated that “Harvard
                does not comment on individual gifts or their status” (Aldhous, 2019b: para. 17); on the other side, Kimberly Allen pointed out that “while donors, including
                foundations, may confirm their contributions to the Institute, MIT does not typically comment on the details of gifts or gift agreements.” (ibid.: para. 26).
              </p>
              <p>
                In his remix of confession and vindication of former Media Lab director Joi Ito Harvard Professor Lawrence Lessig (2019) exonerated such secrecy in a very
                original way. To begin with, Lessig resorted to his piece “Against Transparency”, where he claimed that the demand for transparency in private donations
                to politicians could lead not only to misleading situations but also to distraction from the most imperative democratic requirement in his view: “A system
                of publicly funded elections would make it impossible to suggest that the reason some member of Congress voted the way he voted was because of money”
                (Lessig, 2009: para. 54). Nonetheless, instead of seeking plaudit by transferring his thesis to the academic world in order to further “a system of publicly
                funded universities” as an antidote to the controversy surrounding the murky origin of the donations, Lessig made the argumentative pirouette that all
                universities accept them just to land in the following conclusion:
              </p>
              <p>
                I think that universities should not be the launderers of reputation. I think that they should not accept blood money. Or more precisely, I believe that
                if they are going to accept blood money [...] or the money from people convicted of a crime [...], they should only ever accept that money anonymously. […]
                Everyone seems to treat it as if the anonymity and secrecy around Epstein’s gift are a measure of some kind of moral failing. I see it as exactly the
                opposite. [...] Secrecy is the only saving virtue of accepting money like this. And rather than repeating unreflective paeans to “transparency”, we should
                recognize that in many cases, secrecy is golden. I suspect MIT takes similarly severe steps to keep the academic records of its students secret. Good for
                them, for here, too, transparency would be evil. (Lessig, 2019: para. 15, 17)
              </p>
              <p>
                Given the secretism surrounding the recipient institutions, I have shifted the focus to the donors, applying to them the classic principle of In dubio pro
                reo. Thus, taking to its logical extreme Lessig's argument that the purpose of these donations is the laundering of criminal reputations, I have concluded
                that for Epstein transparency was indeed golden. Consequently, I have given his boasting about scientific philanthropy the benefit of the doubt, just as
                several universities claimed they did when accepting the New York billionaire's funds.
              </p>
              <p>
                Once this methodological choice was made, the next obstacle lied in the memory holes that sometimes affect the Internet. Indeed, I could soon verify the
                sudden vanishing of certain websites, not just of recipient institutions but also of Epstein’s foundations. In order to overcome the information gaps of
                this corporate-philantropic tangle, I have resorted to the journalistic investigation (as well as to some scholarly contributions) that delved into the <a target="_blank" rel="noopener noreferrer" href="https://archive.org/">Internet Archive Wayback Machine</a> to rescue some illustrative websites that in certain cases represent the only trace left of the alleged donations. Those
              journalists have gone even further by using the Freedom of Information Act to get donation records from the Internal Revenue Service (IRS) over the last
              decade, which they sometimes complemented with documents leaked from the universities themselves. Among these muckrackers, there are four especially remarkable:
              </p>
              <p>
                a) Nicholas M. Ciarelli (Harvard Crimson), who shortly after the accusation against Epsein was revealed in July 2006, inquired whether Harvard would
                return the $6.5 million the philanthropist gave to set up Martin A. Nowak’s Program of Evolutionary Dynamics (PED). Harvard’s spokesperson response that
                “Mr. Epstein’s gift is funding important research using mathematics to study areas such as evolutionary theory, viruses, and cancers” (Ciarelli, 2006: para.4)
                represents the first trace of the Pecunia non olet argument guiding my ranking.
              </p>
              <p>
                b) Julie K. Brown (Miami Herald), whose 2018 series “Perversion of Justice” covers all legal, political, police and criminal maneuvers deployed by Epstein
                and his partners, revealing the magnitude and influence of the circle of power he built over the years. After being awarded several times, on April 3, 2019
                she was accused of publishing “fake news” by the aforementioned lawyer Alan Dershowitz (2019). It should be noted that in January 2020 Lessig followed suit
                and sued The New York Times for “clickbait defamation” (Lessig, 2020) over an article titled “A Harvard Professor Doubles Down: If you take Epstein’s Money,
                Do It in Secret” (Bowles, 2019).
              </p>
              <p>
                c) Peter Aldhous (BuzzFeed News), whose 2019 five-part series on Epstein's links to élite universities and scientists becomes an indispensable reference in
                order to understand the academic ramifications of the aforementioned corporate-philantropic tangle.
              </p>
              <p>
                d) Ronan Farrow (The New Yorker), whose 2019 article on the MIT Media Lab secrecy in managing Epstein's substantial contributions caused an institutional
                commotion leading to the immediate resignation of Joi Ito (and also to Lessig's article mentioned above) and whose consequences are yet to be fully seen.
              </p>
              <p>
                In fact, the last source to be highlighted is the fact-finding report concerning Epstein’s “interactions” with the MIT commissioned by its President L. Rafael Reif
                shortly after Farrow's article appeared. This report, produced by the law firm Goodwin Procter (Braceras, Chunias & Martin, 2020), conducted a thorough analysis
                of the database, 610,000 emails and other corporate documents linked to Epstein's financial contributions to MIT, as well as on 73 interviews with 59 witnesses
                to the events. Based on this data collection, the report probes into the donations made directly by Epstein or his foundations (discarding those whose
                intermediation is attributed to the broker) and the MIT executives that were aware of them and, where applicable, approved them.
              </p>
              <p>
                As of this writing, no other university has reacted to the journalistic revelations with a report similar to that of MIT. Only four of them have issued
                brief official statements about the donations they obtained via Epstein (OSU, 2019; MSHS, 2019; Bacow, 2019; SFI, 2019). Taking this into account, the JER™
                presented here is a beta version of what must necessarily be a work in progress. For that matter, in addition to commiting to update the database as the
                investigations of the Epstein case continues, I hereby offer all universities that might not find themselves faithfully represented in the ranking to
                include them for free if they send me their updates to <Mailto email="fcabello@uma.es"/>.
              </p>
            </div>
          </Section>
       </Main>
      </Layout >
    )
  }
}

export default WhoPage
